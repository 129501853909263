import React, {useReducer} from 'react';
import ProductContext from './ProductContext';
import productReducer from './ProductReducer';
import WeddingListsService from "../../services/domain/WeddingListsService";

const ProductProvider = props => {

  const initialState = {
    listProducts: [],
    activeProduct: {},
    isFetching: true,
    errorMessage: null,
    showToastAddSuccess: false,
    showToastRemoveSuccess: false,
    showToastFailure: false,
    showToastOrRedirectOnAddFailure: false,
    showCategoriesFilter: false,
    showInspirationListFilter: false,
    showMarqueFilter: false,
  };


  const [state, dispatch] = useReducer(productReducer, initialState);

  // Get all Products from my list
  const getListProducts = async () => {
    try {
      dispatch({type: 'GET_PRODUCTS_START'});
      const data = await WeddingListsService.getListProducts();
      dispatch({type: 'GET_PRODUCTS_SUCCESS', payload: data});
    } catch (err) {
      dispatch({type: 'GET_PRODUCTS_FAILURE', payload: err.message});
    }
  };

  // Get all Products from list by Ref
  const getListProductsByRef = async (ref) => {
    try {
      dispatch({type: 'GET_PRODUCTS_START'});
      const data = await WeddingListsService.getListProductsByRef(ref);
      dispatch({type: 'GET_PRODUCTS_SUCCESS', payload: data});
    } catch (err) {
      dispatch({type: 'GET_PRODUCTS_FAILURE', payload: err.message});
    }
  };

  // add Product to my list
  const addListProduct = async (product) => {
    try {
      dispatch({type: 'ADD_PRODUCT_START'});
      await WeddingListsService.addListProduct(product.reference, product.quantity);
      dispatch({type: 'ADD_PRODUCT_SUCCESS', payload: product});
    } catch (err) {
      dispatch({type: 'ADD_PRODUCT_FAILURE', payload: err});
    } finally {
      dispatch({type: 'AFTER_NOTIFICATION'})
    }
  };

  // remove Product from my list
  const removeListProduct = async (product) => {
    try {
      dispatch({type: 'REMOVE_PRODUCT_START'});
      await WeddingListsService.removeListProduct(product.reference);
      dispatch({type: 'REMOVE_PRODUCT_SUCCESS', payload: product.reference});

    } catch (err) {
      dispatch({type: 'REMOVE_PRODUCT_FAILURE', payload: err.message});

    }
  };


  // update Product info ( quantity )
  const updateListProduct = async (reference, product) => {
    try {
      dispatch({type: 'UPDATE_PRODUCT_START'});
      const data = await WeddingListsService.updateListProduct(reference, product.reference, product.quantity);
      if (data) {
        dispatch({type: 'UPDATE_PRODUCT_SUCCESS', payload: product});
        return data;
      }
    } catch (err) {
      dispatch({type: 'UPDATE_PRODUCT_FAILURE', payload: err.message});
    }
  }

  // add a custom product to my list
  const addCustomListProduct = async (product, is_vdm) => {
    try {
      dispatch({type: 'ADD_CUSTOM_PRODUCT_START'});
      const data = await WeddingListsService.addCustomListProduct(product, is_vdm);
      dispatch({type: 'ADD_CUSTOM_PRODUCT_SUCCESS', payload: data});
    } catch (err) {
      dispatch({type: 'ADD_CUSTOM_PRODUCT_FAILURE', payload: err.message});
    }
  };

  // updates a custom product
  const updateCustomListProduct = async (product, is_vdm) => {
    try {
      dispatch({type: 'UPDATE_CUSTOM_PRODUCT_START'});
      const data = await WeddingListsService.updateCustomListProduct(product, is_vdm);
      if (data) {
        dispatch({type: 'UPDATE_CUSTOM_PRODUCT_SUCCESS', payload: data});
        return data;
      }
    } catch (err) {
      dispatch({type: 'UPDATE_CUSTOM_PRODUCT_FAILURE', payload: err.message});
    }
  };

  const setActiveProduct = (product) => {
    dispatch({type: 'SET_ACTIVE_PRODUCT', payload: product});
  };


  const toggleCategoriesFilter = () => {
    dispatch({type: 'TOGGLE_CATEGORIES_FILTER'});
  };

  const toggleInspirationListFilter = () => {
    dispatch({type: 'TOGGLE_INSPIRATION_LIST_FILTER'});
  };

  const toggleMarqueFilter = () => {
    dispatch({type: 'TOGGLE_MARQUE_FILTER'});
  };


  return (
    <ProductContext.Provider
      value={{
        listProducts: state.listProducts,
        activeProduct: state.activeProduct,
        isFetching: state.isFetching,
        errorMessage: state.errorMessage,
        showToastAddSuccess: state.showToastAddSuccess,
        showToastRemoveSuccess: state.showToastRemoveSuccess,
        showToastFailure: state.showToastFailure,
        showToastUpdateSuccess: state.showToastUpdateSuccess,
        showUpdateToastFailure: state.showUpdateToastFailure,
        showToastAddCustomSuccess: state.showToastAddCustomSuccess,
        showToastOrRedirectOnAddFailure: state.showToastOrRedirectOnAddFailure,
        showCategoriesFilter: state.showCategoriesFilter,
        showInspirationListFilter: state.showInspirationListFilter,
        showMarqueFilter: state.showMarqueFilter,
        getListProducts: getListProducts,
        getListProductsByRef: getListProductsByRef,
        addListProduct: addListProduct,
        removeListProduct: removeListProduct,
        addCustomListProduct: addCustomListProduct,
        updateCustomListProduct: updateCustomListProduct,
        setActiveProduct: setActiveProduct,
        updateListProduct: updateListProduct,
        toggleCategoriesFilter: toggleCategoriesFilter,
        toggleInspirationListFilter: toggleInspirationListFilter,
        toggleMarqueFilter: toggleMarqueFilter
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
